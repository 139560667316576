@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-ExtraLight.woff2') format('woff2'),
        url('./RobotoSlab-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-SemiBold.woff2') format('woff2'),
        url('./RobotoSlab-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-Light.woff2') format('woff2'),
        url('./RobotoSlab-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-Regular.woff2') format('woff2'),
        url('./RobotoSlab-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-Bold.woff2') format('woff2'),
        url('./RobotoSlab-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-Medium.woff2') format('woff2'),
        url('./RobotoSlab-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./RobotoSlab-Thin.woff2') format('woff2'),
        url('./RobotoSlab-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

