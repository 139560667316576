@font-face {
    font-family: 'Inter';
    src: url('./Inter-ExtraBold.woff2') format('woff2'),
        url('./Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Medium.woff2') format('woff2'),
        url('./Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-ExtraLight.woff2') format('woff2'),
        url('./Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Bold.woff2') format('woff2'),
        url('./Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Light.woff2') format('woff2'),
        url('./Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Black.woff2') format('woff2'),
        url('./Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-SemiBold.woff2') format('woff2'),
        url('./Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Regular.woff2') format('woff2'),
        url('./Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('./Inter-Thin.woff2') format('woff2'),
        url('./Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

.sidebar ::-webkit-scrollbar {
    width: 5px;
}

.sidebar ::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.sidebar ::-webkit-scrollbar-thumb {
    background: #888; 
}

.sidebar ::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
