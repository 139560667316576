@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Bold.woff2') format('woff2'),
        url('./NotoSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Medium.woff2') format('woff2'),
        url('./NotoSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Regular.woff2') format('woff2'),
        url('./NotoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Italic.woff2') format('woff2'),
        url('./NotoSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-SemiBold.woff2') format('woff2'),
        url('./NotoSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./NotoSans-Light.woff2') format('woff2'),
        url('./NotoSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

